<template>
  <div class="flex h-screen flex-col justify-between">
    <header class="h-10">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex h-[5em] justify-between">
          <div class="flex">
            <div class="flex flex-shrink-0 items-center">
              <hippo class="h-10 w-auto" />
            </div>
          </div>
        </div>
      </div>
    </header>

    <main class="mb-auto h-10 py-10">
      <slot></slot>
    </main>
    <footer class="h-[6em]">
      <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="mb-10 text-center">
          <h2 class="text-sm font-medium text-white">
            <div class="pt-5 text-indigo-100">
              TeachMeHIPAA<br />© {{ year }} All rights reserved.
            </div>
          </h2>
        </div>
      </div>
    </footer>
  </div>
</template>
<script setup>
import hippo from "~/assets/images/Logo.svg";

const year = new Date().getFullYear();
</script>
<style>
body {
  background-color: rgb(67 56 202);
}
</style>
